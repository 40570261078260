import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <p className="copyright">
        {' '}
        <span role="img" aria-label="thinking">

        </span>{' '}
        Base Template by Matthew Jigalin, Adapted by Brent Brain
      </p>
    </footer>
  )
}

export default Footer
